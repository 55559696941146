/* eslint-disable @typescript-eslint/no-explicit-any */

export type UserPartial = {
  readonly id: string;
  readonly username: string;
  readonly email: string;
  readonly emailVerified: boolean;
  readonly firstName: string;
  readonly lastName: string;
  readonly createdTimestamp: number;
  readonly attributes: UserAttributes;
};

export type UserAttributes = { readonly [attributeName: string]: ReadonlyArray<string> };

export type User = UserPartial & {
  readonly [
    key: Exclude<string, "id" | "username" | "email" | "firstName" | "lastName" | "attributes" | "emailVerified">
  ]: any;
};

export type Realm = {
  readonly internationalizationEnabled?: boolean;
  readonly supportedLocales?: ReadonlyArray<string>;
  readonly defaultLocale?: string;
};
