import React from "react";
import * as Sentry from "@sentry/react";
import { Dispatch } from "@typescript-tea/core";
import { Texts } from "@ka/shared";
import * as State from "./state";
import * as Form from "../form";
import { SpinnerOverlay } from "../../elements/spinner-overlay";
import { ErrorHandler } from "../../error-handler";
import { SimplePage } from "../../elements/page";
import { Button } from "../../elements";

interface Props {
  readonly state: State.State;
  readonly dispatch: Dispatch<State.Action>;
}

export function view(props: Props): JSX.Element {
  return (
    <ErrorHandler>
      <Root {...props} />
    </ErrorHandler>
  );
}

function Root({ state, dispatch }: Props): JSX.Element {
  React.useEffect(() => {
    if (state.type === "ErrorState") {
      // Forward to error handler and log to Sentry
      throw new Error(`Error state: ${state.reason}`);
    } else if (state.type === "LoggedInState" && state.errorResponse) {
      throw new Error(`Got HTTP error during request`);
    } else if (state.type === "LoggedOutState") {
      // This should be rare
      Sentry.captureMessage("LoggedOutState");
    }
  }, [state.type]);

  if (state.type === "ErrorState") {
    return <div />;
  }

  if (state.type === "WaitingForUserSessionState") {
    return <SpinnerOverlay />;
  }

  if (state.type === "LoggedOutState") {
    return (
      <SimplePage heading="Signed out">
        <div>You have been signed out of your Systemair account.</div>
        <div>
          <Button label="Sign in again" onClick={() => dispatch(State.Action.Login())} />
        </div>
      </SimplePage>
    );
  }

  if (!state.formState || !state.translationsFile) {
    return <SpinnerOverlay />;
  }

  const translate = Texts.createTranslateFn(state.translationsFile, state.locale);

  const sharedState = State.buildSharedState(state);

  return (
    <div className="h-full">
      {/* <button className="btn btn-primary w-full" onClick={() => dispatch(State.Action.SilentRenew())} /> */}
      <Form.View
        dispatch={Dispatch.map(State.Action.DispatchForm, dispatch)}
        sharedState={sharedState}
        state={state.formState}
        activeUser={state.activeUser}
        waitingForResponse={state.waitingForResponse}
        translate={translate}
      />
    </div>
  );
}
