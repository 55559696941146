import { AttributeValue, getApiValue, User } from ".";

export function updateAttribute(user: User, value: AttributeValue): User {
  const newAttributes = {
    ...user.attributes,
    ...getApiValue(value),
  };
  return {
    ...user,
    attributes: newAttributes as User["attributes"],
  };
}
