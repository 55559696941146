import * as React from "react";

export interface ButtonProps {
  readonly label: string;
  readonly type?: "primary" | "secondary" | "danger" | "nav";
  readonly onClick?: () => void;
  readonly disabled?: boolean;
  readonly className?: string;
}

export function Button(props: ButtonProps): React.ReactElement<ButtonProps> {
  const style = props.type || "primary";
  let className = "btn btn-primary w-full mt-16";
  switch (style) {
    case "primary":
      className += " btn-primary";
      break;
    case "secondary":
      className += " btn-secondary";
      break;
    case "danger":
      className += " btn-danger";
      break;
    case "nav":
      className += " btn-nav";
      break;
    default:
      break;
  }
  if (props.disabled) {
    className += " btn-disabled";
  }
  if (props.className) {
    className += ` ${props.className}`;
  }
  return (
    <div>
      <button
        className={className}
        onClick={() => {
          if (!props.disabled && props.onClick) {
            props.onClick();
          }
        }}
      >
        {props.label}
      </button>
    </div>
  );
}
