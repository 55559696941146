import React from "react";
import { Dispatch } from "@typescript-tea/core";
import { Keycloak, User, Texts, FormData } from "@ka/shared";
import { ValidationResult, ValidationResults } from "@ka/shared/src/attribute-validator";
import * as State from "./state";
import { Checkbox, H3, Icon } from "../../elements";
import { SpinnerOverlay } from "../../elements/spinner-overlay";
import { PageContainer, PageHeader, SimplePage } from "../../elements/page";
import { LanguageSelector } from "./language-selector";
import { SharedState } from "../root";
import { Fal } from "../../elements/icons";

const texts = Texts.texts;

export function View({
  sharedState,
  state,
  dispatch,
  activeUser,
  waitingForResponse,
  translate,
}: {
  readonly sharedState: SharedState;
  readonly state: State.State;
  readonly dispatch: Dispatch<State.Action>;
  readonly activeUser: User.ActiveUser;
  readonly waitingForResponse: boolean;
  readonly translate: Texts.TranslateFn;
}): JSX.Element {
  const requireValidation = Object.values(state.attributeValues).some((v) => !!v.attribute.validator);

  const formRef = React.useRef<HTMLFormElement>(null);

  const [showLanguageSelector, setShowLanguageSelector] = React.useState(false);

  // Validate before submission if some attributes require validation
  React.useEffect(() => {
    if (!formRef.current) {
      return undefined;
    }
    const el = formRef.current;
    const cb = (ev: SubmitEvent): void => {
      if (state.submitStatus === "submitting") {
        ev.preventDefault();
      } else if (requireValidation) {
        ev.preventDefault();
        dispatch(State.Action.ValidateClaims(() => formRef.current?.submit()));
      } else {
        dispatch(State.Action.SetSubmitStatus("submitting"));
      }
    };
    el.addEventListener("submit", cb);
    return () => el.removeEventListener("submit", cb);
  }, [formRef.current, state.submitStatus, requireValidation]);

  if (!state.customerNumberAttributes || !state.additionalAttributes || !state.fromData || !state.legalTexts) {
    return <SpinnerOverlay />;
  }

  if (!state.redirectToUrl) {
    return <SimplePage heading={translate(texts.complete_account)} message={translate(texts.no_redirect_specified)} />;
  }

  if (Object.keys(state.attributeValues).length === 0) {
    return <SimplePage heading={translate(texts.complete_account)} message={translate(texts.missing_claims)} />;
  }

  const customerNumberClaims = state.missingClaims.includes(State.customerNumberClaim)
    ? state.missingClaims.filter((c) => State.customerNumberClaims.includes(c))
    : [];
  const otherClaims = state.missingClaims.filter((c) => !customerNumberClaims.includes(c));

  return (
    <div className="flex flex-col items-center justify-between h-full space-y-16">
      {!showLanguageSelector ? (
        <Page
          mainContent={
            <div>
              {state.submitStatus !== "ready" && <SpinnerOverlay />}
              <form ref={formRef} method="post" action="/update-claims">
                <input type="hidden" name="access_token" value={activeUser.accessToken} />
                <input type="hidden" name="redirect_to" value={state.redirectToUrl} />
                <input type="hidden" name="logout_on_confirm" value={state.logoutOnConfirm ? "1" : "0"} />
                <input type="hidden" name="sys_sso_location_available" value={state.sysSsoLocationAvaialable} />
                <div className="space-y-24">
                  {customerNumberClaims.length > 0 && (
                    <AttributeFormInput
                      dispatch={dispatch}
                      heading={state.fromData?.storyblok.customerNumberInfo.mainInfo?.title}
                      message={state.fromData?.storyblok.customerNumberInfo.mainInfo?.message}
                      attributeConfig={state.customerNumberAttributes}
                      attributeValues={state.attributeValues}
                      translate={translate}
                      countries={state.fromData.locales.countries}
                      validationResults={state.validationResults}
                    />
                  )}
                  {(otherClaims.length > 0 || state.userInfoAttributes.length > 0) && (
                    <AttributeFormInput
                      dispatch={dispatch}
                      heading={translate(texts.complete_account)}
                      attributeConfig={[...state.userInfoAttributes, ...state.additionalAttributes]}
                      attributeValues={state.attributeValues}
                      translate={translate}
                      countries={state.fromData.locales.countries}
                      validationResults={state.validationResults}
                    />
                  )}
                </div>
                <button
                  type="submit"
                  disabled={state.submitStatus !== "ready"}
                  className="btn btn-primary w-full mt-16"
                >
                  {translate(texts.confirm_and_continue)}
                </button>
              </form>
            </div>
          }
          sideContent={
            customerNumberClaims.length > 0 && state.fromData.storyblok.customerNumberInfo.paragraphs.length > 0 ? (
              <CustomerNumberSideContent paragraphs={state.fromData.storyblok.customerNumberInfo.paragraphs} />
            ) : undefined
          }
          waitingForResponse={waitingForResponse}
        />
      ) : (
        <div />
      )}
      <footer className="flex flex-row justify-between flex-wrap items-center gap-y-16 px-400 pb-300 text-label-small w-full">
        <ul className="flex flex-wrap p-0 mb-0 gap-x-48 gap-y-16 list-none">
          {state.legalTexts.map((t) => (
            <li key={t.title}>
              <a className="text-primary hover:text-primary-light no-underline block" href={t.link} target="_blank">
                {t.title}
              </a>
            </li>
          ))}
        </ul>
        <LanguageSelector
          translate={translate}
          onSetSelectorVisiblity={setShowLanguageSelector}
          onSetLocale={(locale) => dispatch(State.Action.SetLocale(locale))}
          countries={state.fromData.locales.countries}
          availableLocales={sharedState.availableLocales}
          showSelector={showLanguageSelector}
          selectedLocale={sharedState.locale}
        />
      </footer>
    </div>
  );
}

function AttributeFormInput({
  dispatch,
  heading,
  message,
  attributeConfig,
  attributeValues,
  translate,
  countries,
  validationResults,
}: {
  readonly dispatch: Dispatch<State.Action>;
  readonly heading: string;
  readonly message?: string;
  readonly attributeConfig: Keycloak.AttributeConfiguration;
  readonly attributeValues: State.AttributeValues;
  readonly translate: Texts.TranslateFn;
  readonly countries: ReadonlyArray<FormData.Country>;
  readonly validationResults: ValidationResults;
}): JSX.Element {
  return (
    <div>
      <PageHeader heading={heading} subtitle={message} />
      <div className="flex flex-col space-y-16 w-full">
        {attributeConfig
          .filter((ac) => !!attributeValues[ac.name])
          .map((config) => {
            const v = attributeValues[config.name];
            const validationResult = validationResults[v.attribute.name];
            const message =
              validationResult !== undefined && !validationResult.valid
                ? translate(texts[validationResult.messageKey])
                : undefined;
            return (
              <div key={v.attribute.name} className={message ? "was-invalidated" : ""}>
                <AttributeValue
                  value={v}
                  onChange={(newValue) => dispatch(State.Action.UpdateClaim(newValue))}
                  translate={translate}
                  countries={countries}
                  validationResult={validationResults[v.attribute.name]}
                  message={message}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}

function Page({
  mainContent,
  sideContent,
  waitingForResponse,
}: {
  readonly mainContent: React.ReactNode;
  readonly sideContent?: React.ReactNode;
  readonly waitingForResponse: boolean;
}): JSX.Element {
  return (
    <div>
      <div className="text-label-medium flex flex-row mx-auto">
        <PageContainer>
          <div className="absolute top-8 right-8">{waitingForResponse ? <SpinnerOverlay /> : undefined}</div>
          {mainContent}
        </PageContainer>
        {sideContent && <div className="bg-neutral-100 max-w-sm py-24 px-40">{sideContent}</div>}
      </div>
    </div>
  );
}

function CustomerNumberSideContent({
  paragraphs,
}: {
  readonly paragraphs: ReadonlyArray<FormData.Paragraph>;
}): JSX.Element {
  return (
    <div className="flex flex-col space-y-16">
      {paragraphs.map(({ image, heading, text, email, phone }) => (
        <div key={heading} className="flex flex-row">
          <div className="min-w-52 mr-16">
            <img src={image} alt={heading} />
          </div>
          <div>
            <H3 className="mt-24 mb-0">{heading}</H3>
            <p>{text}</p>
            {email && (
              <p>
                <a className="flex flex-row space-x-8" href={`mailto:${email.email}`} title={email.email}>
                  {email.image && <img src={email.image} />}
                  <span>{email.email}</span>
                </a>
              </p>
            )}
            {phone && (
              <p>
                <a className="flex flex-row space-x-8" href={`tel:${phone.phone}`} title={phone.phone}>
                  {phone.image && <img src={phone.image} />}
                  <span>{phone.phone}</span>
                </a>
              </p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

function AttributeValue({
  value,
  onChange,
  translate,
  countries,
  message,
}: {
  readonly value: Keycloak.AttributeValue;
  readonly onChange: (newValue: Keycloak.AttributeValue) => void;
  readonly translate: Texts.TranslateFn;
  readonly countries: ReadonlyArray<FormData.Country>;
  readonly validationResult: ValidationResult | undefined;
  readonly message: string | undefined;
}): JSX.Element {
  const readOnly = value.attribute.readOnly;
  const optional = value.attribute.optional;
  const formName = `claim_${value.attribute.name}`;
  switch (value.type) {
    case "text":
      return readOnly ? (
        <div>{value.value}</div>
      ) : value.attribute.name === State.customerNumberNotesClaim ? (
        <TextAreaInput
          label={translate(texts.attribute_name(value.attribute.name))}
          name={formName}
          disabled={readOnly}
          optional={optional}
          value={value.value}
          onChange={(v) => onChange(Keycloak.createTextValue(value.attribute, v))}
          translate={translate}
          message={message}
        />
      ) : (
        <TextInput
          label={translate(texts.attribute_name(value.attribute.name))}
          name={formName}
          disabled={readOnly}
          optional={optional}
          value={value.value}
          onChange={(v) => onChange(Keycloak.createTextValue(value.attribute, v))}
          message={message}
        />
      );
    case "bool":
      return (
        <CheckBoxInput
          label={translate(texts.attribute_name(value.attribute.name))}
          name={formName}
          disabled={readOnly}
          checked={value.value}
          onChange={() => onChange(Keycloak.createBoolValue(value.attribute, !value.value))}
          message={message}
        />
      );
    case "discrete": {
      const options = value.attribute.options.map((o) => ({
        id: o,
        name: translate(texts.attribute_value(value.attribute.name, o)),
      }));
      return (
        <SelectInput
          label={translate(texts.attribute_name(value.attribute.name))}
          name={formName}
          value={value.value}
          disabled={readOnly}
          optional={optional}
          options={options}
          onChange={(v) => onChange(Keycloak.createDiscreteValue(value.attribute, v))}
          message={message}
        />
      );
    }
    case "locale": {
      return (
        <LocaleInput
          name={formName}
          locale={value.locale}
          countries={countries}
          availableCountryCodes={
            value.attribute.limitAvailableCountyCodes ? value.attribute.availableCountryCodes : undefined
          }
          disabled={readOnly}
          optional={optional}
          onChange={(v) => onChange(Keycloak.createLocaleValue(value.attribute, v))}
          translate={translate}
          message={message}
        />
      );
    }
    default:
      return <span />;
  }
}

function TextInput(props: {
  readonly value: string;
  readonly onChange: (value: string) => void;
  readonly placeholder?: string;
  readonly label: string;
  readonly disabled?: boolean;
  readonly name: string;
  readonly optional: boolean;
  readonly message?: string;
}): JSX.Element {
  return (
    <>
      <Label label={props.label} name={props.name} optional={props.optional} />
      <input
        disabled={props.disabled}
        required={!props.optional}
        className="w-full form-input-fixed"
        value={props.value}
        type="text"
        id={props.name}
        name={props.name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange(e.currentTarget.value)}
        maxLength={Keycloak.maxValueLength}
      />
      <Message message={props.message} />
    </>
  );
}

function TextAreaInput(props: {
  readonly value: string;
  readonly onChange: (value: string) => void;
  readonly placeholder?: string;
  readonly label: string;
  readonly disabled?: boolean;
  readonly name: string;
  readonly optional: boolean;
  readonly translate: Texts.TranslateFn;
  readonly message?: string;
}): JSX.Element {
  return (
    <div>
      <Label label={props.label} name={props.name} optional={props.optional} />
      <div>
        <textarea
          disabled={props.disabled}
          required={!props.optional}
          className="w-full form-input-fixed"
          value={props.value}
          rows={6}
          id={props.name}
          name={props.name}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => props.onChange(e.currentTarget.value)}
          maxLength={Keycloak.maxValueLength}
        />
        {props.value.length > Keycloak.maxValueLength - 50 ? (
          <div className="text-right text-neutral-600 text-label-small mt-8">{`${props.translate(texts.text_length)}: ${
            props.value.length
          } / ${Keycloak.maxValueLength}`}</div>
        ) : undefined}
      </div>
      <Message message={props.message} />
    </div>
  );
}

function CheckBoxInput(props: {
  readonly checked: boolean;
  readonly onChange: (value: boolean) => void;
  readonly placeholder?: string;
  readonly label: string;
  readonly disabled?: boolean;
  readonly name: string;
  readonly message?: string;
}): JSX.Element {
  return (
    <div className="flex flex-row items-center">
      <Checkbox
        label={props.label}
        checked={props.checked}
        checkedChanged={(checked) => props.onChange(!checked)}
        disabled={props.disabled}
        name={props.name}
      />
    </div>
  );
}

function SelectInput(props: {
  readonly value: string | undefined;
  readonly onChange: (value: string) => void;
  readonly placeholder?: string;
  readonly label: string;
  readonly disabled?: boolean;
  readonly name: string;
  readonly options: ReadonlyArray<{ readonly id: string; readonly name: string }>;
  readonly optional: boolean;
  readonly message?: string;
}): JSX.Element {
  const selectOptions = [];
  if (!props.value) {
    selectOptions.push({ id: "", name: "" });
  } else if (!props.options.some((o) => o.id === props.value)) {
    selectOptions.push({ id: props.value, name: props.value });
  }
  selectOptions.push(...props.options);
  return (
    <div>
      <Label label={props.label} name={props.name} optional={props.optional} />
      <div className={"dropdown-wrapper flex justify-between"}>
        <select
          disabled={props.disabled}
          required={!props.optional}
          className="w-full form-input-fixed form-dropdown bg-white"
          name={props.name}
          value={props.value}
          onChange={(e) => {
            const value = props.options.find((o) => o.id === e.target.value);
            if (value) {
              props.onChange(value.id);
            }
          }}
        >
          {selectOptions.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
        <ArrowDown />
      </div>
      <Message message={props.message} />
    </div>
  );
}

function ArrowDown(): JSX.Element {
  return (
    <svg className="print:hidden" style={{ height: "1em" }} viewBox="0 0 384 512">
      <path
        fill="currentColor"
        d="M360.5 217.5l-152 143.1C203.9 365.8 197.9 368 192 368s-11.88-2.188-16.5-6.562L23.5 217.5C13.87 208.3 13.47 193.1 22.56 183.5C31.69 173.8 46.94 173.5 56.5 182.6L192 310.9l135.5-128.4c9.562-9.094 24.75-8.75 33.94 .9375C370.5 193.1 370.1 208.3 360.5 217.5z"
      ></path>
    </svg>
  );
}

function LocaleInput({
  locale,
  name,
  disabled,
  onChange,
  translate,
  countries: allCountries,
  availableCountryCodes,
  optional,
  message,
}: {
  readonly locale: string | undefined;
  readonly name: string;
  readonly disabled?: boolean;
  readonly onChange: (locale: string) => void;
  readonly translate: Texts.TranslateFn;
  readonly countries: ReadonlyArray<FormData.Country>;
  readonly availableCountryCodes: ReadonlyArray<string> | undefined;
  readonly optional: boolean;
  readonly message?: string;
}): JSX.Element {
  const countries =
    availableCountryCodes !== undefined
      ? allCountries.filter((c) => availableCountryCodes.includes(c.countryCode.toUpperCase()))
      : allCountries;
  const attributeCountryCode = (locale?.split("-") || [])[1];
  const attributeLanguageCode = (locale?.split("-") || [])[0];
  const selectedCountry = countries.find(
    (c) => c.countryCode === attributeCountryCode && c.languages.some((l) => l.languageCode === attributeLanguageCode)
  );
  const selectedLanguage = selectedCountry?.languages.find((l) => l.languageCode === attributeLanguageCode);
  const countryOptions = countries.map((c) => ({ id: c.countryCode, name: c.englishCountryName }));
  const languageOptions =
    selectedCountry?.languages.map((l) => ({ id: l.languageCode, name: l.englishLanguageName })) || [];

  return (
    <>
      <div>
        <input type="hidden" name={name} value={locale} />
        <SelectInput
          label={translate(texts.country)}
          name={""}
          value={selectedCountry?.countryCode || ""}
          disabled={disabled}
          optional={optional}
          options={countryOptions}
          message={message}
          onChange={(v) => {
            const country = countries.find((c) => c.countryCode === v);
            if (country) {
              const locale = `${country.languages[0].languageCode}-${country.countryCode}`;
              onChange(locale);
            }
          }}
        />
      </div>
      {languageOptions.length > 1 && (
        <div className="mt-16">
          <SelectInput
            label={translate(texts.language)}
            name={""}
            value={selectedLanguage?.languageCode || ""}
            disabled={disabled}
            options={languageOptions}
            optional={optional}
            message={message}
            onChange={(v) => {
              const language = selectedCountry?.languages.find((l) => l.languageCode === v);
              if (language && selectedCountry) {
                const locale = `${language.languageCode}-${selectedCountry.countryCode}`;
                onChange(locale);
              }
            }}
          />
        </div>
      )}
    </>
  );
}

function Message({ message }: { readonly message?: string }): JSX.Element | null {
  if (!message) {
    return null;
  }
  return (
    <div className="pt-8 flex flex-row gap-6 flex-nowrap border-danger-400 text-label-small">
      <Icon icon={Fal.faTriangleExclamation} />
      <span>{message}</span>
    </div>
  );
}

function Label({
  name,
  label,
  optional,
}: {
  readonly name: string;
  readonly label: string;
  readonly optional: boolean;
}): JSX.Element {
  return (
    <label htmlFor={name} className={"pb-8 flex flex-row flex-nowrap"}>
      <span className={`${!optional ? "font-bold" : ""}`}>{label}</span>
      {!optional && <span>{!optional ? "*" : ""}</span>}
    </label>
  );
}
