import { KeyParams, NormalizedText } from "./normalized-text";
import { replaceCurly } from "./replace-curly";

export type TranslationsFile = { readonly [languageCode: string]: Translations };

export type Translations = { readonly [key: string]: string | undefined };

export const defaultLanguage: LanguageCode = "en";

export type LanguageCode = "en" | "en-GB" | "sv-SE";

export type TextTranslateFn<TTextTypes> = (text: TTextTypes) => string;

export function translateNormalizedText(translations: Translations, text: NormalizedText): string {
  const rawTranslation = translations[text.key];
  const translation =
    rawTranslation === undefined
      ? getUndefinedTextPlaceholderFromNormalizedText(text)
      : replaceCurly(rawTranslation, text.params);
  return translation;
}

function getUndefinedTextPlaceholderFromNormalizedText(text: NormalizedText): string {
  return getUndefinedTextPlaceholder(text.key, text.params);
}

export function getUndefinedTextPlaceholder(key: string, params?: KeyParams): string {
  return params && Object.keys(params).length > 0 ? `{${key} ${JSON.stringify(params)}}` : `{${key}}`;
}

export function getUndefinedPropertyNamePlaceholder(propertyName: string): string {
  return `{${propertyName}}`;
}
