import { exhaustiveCheck } from "ts-exhaustive-check";
import { defaultLanguage, TextTranslateFn, translateNormalizedText, TranslationsFile } from "./translation";
import { NormalizedText, normalizedText } from "./normalized-text";
import { TextTypes } from "./texts";

export type TranslateFn = TextTranslateFn<TextTypes>;

export function createTranslateFn(translationsFile: TranslationsFile, languageCode: string): TranslateFn {
  return (text) =>
    translateNormalizedText(
      { ...translationsFile[defaultLanguage.toLowerCase()], ...(translationsFile[languageCode.toLowerCase()] || {}) },
      normalizeText(text)
    );
}

function normalizeText(text: TextTypes): NormalizedText {
  switch (text.type) {
    case "TextKey": {
      return normalizedText(text.key, text.params);
    }
    case "Dummy": {
      return normalizedText(text.key, text.params);
    }
    default: {
      return exhaustiveCheck(text);
    }
  }
}
