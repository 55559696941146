// Getting a user from the OIDC token is managed in this file
// We need to handle creating a User from the token in the Client and Server
// The token looks a little different but the information in it is the same

export type UserClaims = { readonly [key: string]: string };

export type ActiveUser = {
  readonly externalId: string;
  readonly userName: string;
  readonly email: string;
  readonly accessToken: string;
  readonly claims: UserClaims;
  readonly firstName: string;
  readonly lastName: string;
};

export interface UntypedToken {
  readonly [key: string]: string;
}
export type DecodedToken = UntypedToken;

export type ActiveUserError = {
  readonly type: "error";
  readonly reason: string;
};

interface ClientToken {
  readonly profile: unknown;
}
type ServerToken = { readonly [key: string]: string };

export function buildActiveUser(token: ClientToken | ServerToken, accessToken: string): ActiveUser | ActiveUserError {
  if (isClientToken(token)) {
    const claims = token.profile as UntypedToken;
    return {
      externalId: claims.sub,
      userName: claims["name"],
      email: claims["email"],
      accessToken: accessToken,
      claims: claims,
      firstName: claims["given_name"] || "",
      lastName: claims["family_name"] || "",
    };
  } else {
    return {
      externalId: token["sub"],
      userName: token["name"],
      email: token["email"],
      accessToken: accessToken,
      claims: token,
      firstName: token["given_name"] || "",
      lastName: token["family_name"] || "",
    };
  }
}

export function isValidUser(activeUser: ActiveUser | ActiveUserError): activeUser is ActiveUser {
  if ((activeUser as ActiveUserError).type !== undefined) {
    return false;
  }

  return true;
}

function isClientToken(token: ClientToken | ServerToken): token is ClientToken {
  if ((token as ClientToken).profile !== undefined) {
    return true;
  }

  return false;
}
