import { exhaustiveCheck } from "ts-exhaustive-check";
import { UserClaims } from "../user";

// Max length an attribute value can have
export const maxValueLength = 255;

// "Fake" attribute names used to update first/last name using the attribut updating logic
export const lastNameAttributeName = "lastName";
export const firstNameAttributeName = "firstName";

export type AttributeBase = {
  readonly name: string;
  readonly realm: string;
  readonly readOnly: boolean;
  readonly optional: boolean;
  readonly validator?: string;
};
export type TextAttribute = AttributeBase & { readonly type: "text" };
export type BoolAttribute = AttributeBase & { readonly type: "bool" };
export type DiscreteAttribute = AttributeBase & { readonly type: "discrete"; readonly options: ReadonlyArray<string> };
export type LocaleAttribute = AttributeBase & {
  readonly type: "locale";
  readonly countryAttributeName: string;
  readonly languageAttributeName: string;
  readonly limitAvailableCountyCodes: boolean;
  readonly availableCountryCodes: ReadonlyArray<string>;
};
export type Attribute = TextAttribute | BoolAttribute | DiscreteAttribute | LocaleAttribute;

export type AttributeConfiguration = ReadonlyArray<Attribute>;

export type TextValue = { readonly type: "text"; readonly attribute: TextAttribute; readonly value: string };
export type BoolValue = { readonly type: "bool"; readonly attribute: BoolAttribute; readonly value: boolean };
export type DiscreteValue = {
  readonly type: "discrete";
  readonly attribute: DiscreteAttribute;
  readonly value: string | undefined;
};
export type LocaleValue = {
  readonly type: "locale";
  readonly attribute: LocaleAttribute;
  readonly locale: string | undefined;
};

export type AttributeValue = TextValue | BoolValue | DiscreteValue | LocaleValue;

export type ApiValue = { readonly name: string; readonly value: string };

export function attributeValuesFromClaims(
  attributeConfiguration: AttributeConfiguration,
  userClaims: UserClaims
): ReadonlyArray<AttributeValue> {
  return attributeConfiguration.map((attribute) => {
    switch (attribute.type) {
      case "text":
        return createTextValue(attribute, userClaims[attribute.name] || "");
      case "bool":
        return createBoolValue(attribute, (userClaims[attribute.name] || "").toLowerCase() === "true");
      case "discrete":
        return createDiscreteValue(attribute, userClaims[attribute.name] || "");
      case "locale": {
        const language = userClaims[attribute.languageAttributeName];
        const country = userClaims[attribute.countryAttributeName];
        const locale = language && country ? `${language}-${country}` : "";
        return createLocaleValue(attribute, locale);
      }
      default:
        exhaustiveCheck(attribute, true);
        return createTextValue({} as TextAttribute, "");
    }
  });
}

export function getApiValueFromString(attribute: Attribute, value: string): ReadonlyArray<ApiValue> {
  switch (attribute.type) {
    case "text":
      return [{ name: attribute.name, value: value }];
    case "bool":
      return [{ name: attribute.name, value: value === "true" ? "true" : "false" }];
    case "discrete":
      return [{ name: attribute.name, value: value }];
    case "locale": {
      const localeParts = value?.split("-") || [];
      const language = localeParts[0];
      const country = localeParts[1];
      if (!language || !country) {
        return [];
      }
      return [
        { name: attribute.languageAttributeName, value: language },
        { name: attribute.countryAttributeName, value: country },
      ];
    }
    default:
      return exhaustiveCheck(attribute, true);
  }
}

export function getKeycloakAttributeName(attribute: Attribute): ReadonlyArray<string> {
  switch (attribute.type) {
    case "text":
    case "bool":
    case "discrete":
      return [attribute.name];
    case "locale": {
      return [attribute.languageAttributeName, attribute.countryAttributeName];
    }
    default:
      return exhaustiveCheck(attribute, true);
  }
}

export function getApiValue(value: AttributeValue): { readonly [attribte: string]: readonly [string | undefined] } {
  switch (value.type) {
    case "text":
      return { [value.attribute.name]: [value.value] };
    case "bool":
      return { [value.attribute.name]: [value.value ? "true" : "false"] };
    case "discrete":
      return { [value.attribute.name]: [value.value !== "" ? value.value : undefined] };
    case "locale": {
      const localeParts = value.locale?.split("-") || [];
      const language = localeParts[0];
      const country = localeParts[1];
      if (!language || !country) {
        return {};
      }
      return { [value.attribute.languageAttributeName]: [language], [value.attribute.countryAttributeName]: [country] };
    }
    default:
      return exhaustiveCheck(value, true);
  }
}

export function createTextValue(attribute: TextAttribute, value: string): AttributeValue {
  return { type: "text", attribute, value: value || "" };
}

export function createBoolValue(attribute: BoolAttribute, value: boolean): AttributeValue {
  return { type: "bool", attribute, value };
}

export function createDiscreteValue(attribute: DiscreteAttribute, value: string): AttributeValue {
  return { type: "discrete", attribute, value: attribute.options.find((o) => o === value) };
}

export function createLocaleValue(attribute: LocaleAttribute, value: string): AttributeValue {
  return { type: "locale", attribute, locale: value };
}
