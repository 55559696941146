import * as React from "react";
import { withTw } from "./with-tw";

export interface CheckboxProps {
  readonly label?: string | React.ReactNode;
  readonly checked: boolean;
  readonly checkedChanged?: (checked: boolean) => void;
  readonly disabled?: boolean;
  readonly className?: string;
  readonly name?: string;
  readonly id?: string;
  readonly error?: string;
}

interface WrapperProps {
  readonly checked: boolean;
}

const CheckboxWrapper = withTw("div", "form-checkbox-border top-auto", ({ checked }: WrapperProps) =>
  checked ? "form-checkbox-checked" : ""
);

interface LabelProps {
  readonly isValid: boolean;
}
const Label = withTw("label", "form-checkbox p-0", ({ isValid }: LabelProps) => (isValid ? "" : "border-danger-800"));

export function Checkbox(props: CheckboxProps): React.ReactElement<CheckboxProps> {
  let className = "";
  if (props.className) {
    className += ` ${props.className}`;
  }
  return (
    <Label title={props.error} isValid={!props.error}>
      <input
        id={props.id}
        name={props.name}
        className={className}
        checked={props.checked}
        value="true"
        type="checkbox"
        disabled={props.disabled}
        onChange={() => {
          if (!props.disabled && props.checkedChanged) {
            props.checkedChanged(!props.checked);
          }
        }}
      />
      {!props.checked && <input name={props.name} value="false" type="hidden" />}
      <CheckboxWrapper checked={props.checked}>
        {props.checked ? (
          <div key="svg" className="form-checkbox-inside p-0">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="check"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
              ></path>
            </svg>
          </div>
        ) : undefined}
      </CheckboxWrapper>
      {props.label && (
        <div className="ml-8 flex items-center">
          {typeof props.label === "string" ? <span>{props.label}</span> : props.label}
        </div>
      )}
    </Label>
  );
}
