/* eslint-disable functional/no-this-expression */
import * as React from "react";
import * as Sentry from "@sentry/react";
import { Button } from "../elements";
import { SimplePage } from "../elements/page";

export interface ErrorObject {
  readonly message: string;
  readonly source: string;
  readonly lineno: number;
  readonly colno: number;
  readonly error: Error | null | undefined;
}

interface Props {
  readonly children: React.ReactNode;
}

interface State {
  readonly error: Error | undefined;
  readonly errorHasOccured: boolean;
}

// eslint-disable-next-line functional/no-class
export class ErrorHandler extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: undefined,
      errorHasOccured: false,
    };
  }

  static getDerivedStateFromError(error: Error): State {
    return {
      error: error,
      errorHasOccured: true,
    };
  }

  componentDidMount(): void {
    window.addEventListener("unhandledrejection", (event: PromiseRejectionEvent) => {
      this.setState({
        error: event.reason,
        errorHasOccured: true,
      });
    });

    window.onerror = (_event: Event | string, _source?: string, _lineno?: number, _colno?: number, error?: Error) => {
      this.setState({
        error: error,
        errorHasOccured: true,
      });
      return true;
    };
  }

  componentDidUpdate(_prevProps: Props, prevState: State): void {
    if (prevState.error !== this.state.error) {
      // Sentry should be capturing all errors automatically but for some reason
      // it's missing some errors. Manually capture here so all errors get sent
      // to Sentry.
      // eslint-disable-next-line no-console
      console.log(this.state.error);
      Sentry.captureException(this.state.error);
    }
  }

  render(): JSX.Element {
    if (this.state.error === undefined && !this.state.errorHasOccured) {
      return <>{this.props.children}</>;
    } else {
      return (
        <SimplePage heading="An error occurred">
          <div>
            We have detected an error and reported it directly to the software development team. We are so sorry about
            the inconvenience.
          </div>
          <div>Press the button below to reload the page and try again.</div>
          <div>
            <Button label="Reload" onClick={() => window.location.reload()} />
          </div>
        </SimplePage>
      );
    }
  }
}
