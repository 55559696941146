import { Cmd } from "@typescript-tea/core";
import { ctorsUnion, CtorsUnion } from "ctors-union";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { User, Texts } from "@ka/shared";
import { HttpEffectManager, NavigationEffectManager as Navigation, OidcEffectManager } from "../../effect-managers";
import * as Route from "../../route";
import * as Routes from "../../routes";
import { setUser } from "../../sentry";
import { userManagerSettings } from "../../user-manager-settings";
import * as Form from "../form";

export type State = ErrorState | WaitingForUserSessionState | LoggedInState | LoggedOutState;

export type LoggedInState = {
  readonly type: "LoggedInState";
  readonly activeUser: User.ActiveUser;
  readonly urlMatch: Route.UrlMatch<Routes.RootLocation> | undefined;
  readonly formState: Form.State | undefined;
  readonly waitingForResponse: boolean;
  readonly errorResponse: boolean;
  readonly translationsFile: Texts.TranslationsFile | undefined;
  readonly locale: string;
};

export type SharedState = {
  readonly locale: string;
  readonly availableLocales: ReadonlyArray<string>;
  readonly activeUser: User.ActiveUser;
};

export type WaitingForUserSessionState = {
  readonly type: "WaitingForUserSessionState";
  readonly urlPath: string;
  readonly urlQuery: string;
};

export type LoggedOutState = {
  readonly type: "LoggedOutState";
};

type RedirectState = { readonly redirectUrl: string | undefined };

export type ErrorState = {
  readonly type: "ErrorState";
  readonly reason: string;
};

export const Action = ctorsUnion({
  UrlChanged: (url: Navigation.Url) => ({ url }),
  UrlRequested: (urlRequest: Navigation.UrlRequest) => ({ urlRequest }),
  HttpStateChanged: (httpState: HttpEffectManager.HttpState) => ({ httpState }),
  UserSessionChanged: (user: OidcEffectManager.User | undefined) => ({ user }),
  AccessTokenRefreshed: (user: OidcEffectManager.User) => ({ user }),
  DispatchForm: (action: Form.Action) => ({ action }),
  ReceivedTranslations: (translationsFile: Texts.TranslationsFile) => ({ translationsFile }),
  Logout: () => ({}),
  Login: () => ({}),
  SilentRenew: () => ({}),
});
export type Action = CtorsUnion<typeof Action>;

export const SharedStateAction = ctorsUnion({
  SetLocale: (locale: string) => ({ locale }),
  NoOp: () => ({}),
});
export type SharedStateAction = CtorsUnion<typeof SharedStateAction>;

export function init(url: Navigation.Url): readonly [State, Cmd<Action>?] {
  const initialUrl = url.path + (url.query ?? "");
  const urlMatch = Routes.parseUrl(initialUrl);

  if (
    urlMatch === undefined ||
    (urlMatch.location.type !== "LoginCallback" && urlMatch.location.type !== "LoggedOut")
  ) {
    // Since this is the init() function we never have a user in our state at this point,
    // so the only thing we can do is to try to login which will either result in a user being
    // found directly (becuase we were already have a token in local storage), or a redirect to the login server
    // If we are already logged in we will have our user session subscription triggered.
    // If we are nog logged in then we will be redirected to the login server.
    // Use the current url as the state to save in the redirect round-trip
    const redirectState: RedirectState = { redirectUrl: initialUrl };
    const uiLocales = getUiLocalesParam(initialUrl);
    const extraQueryParams = uiLocales ? { ui_locales: uiLocales } : undefined;
    return [
      {
        type: "WaitingForUserSessionState",
        urlPath: url.path,
        urlQuery: url.query || "",
      },
      OidcEffectManager.login(userManagerSettings, redirectState, extraQueryParams),
    ];
  }

  if (urlMatch.location.type === "LoginCallback") {
    // We got the login callback, let's process it and if successful the subscription will get a user session
    return [
      {
        type: "WaitingForUserSessionState",
        urlPath: url.path,
        urlQuery: url.query || "",
      },
      OidcEffectManager.processSigninCallback(userManagerSettings),
    ];
  }

  // User logged out and was redirected to our application
  if (urlMatch.location.type === "LoggedOut") {
    return [{ type: "LoggedOutState" }];
  }

  // Should never get here
  return exhaustiveCheck(urlMatch.location, true);
}

function handleSharedStateAction(action: SharedStateAction | undefined, state: State): readonly [State, Cmd<Action>?] {
  if (!action || state.type === "ErrorState") {
    return [state];
  }
  switch (action.type) {
    case "SetLocale": {
      if (state.type !== "LoggedInState") {
        return [state];
      }
      return [
        {
          ...state,
          locale: action.locale,
        },
      ];
    }
    case "NoOp": {
      return [state];
    }
    default: {
      return exhaustiveCheck(action, true);
    }
  }
}

export function update(action: Action, state: State): readonly [State, Cmd<Action>?] {
  if (state.type === "ErrorState") {
    return [state];
  }
  switch (action.type) {
    case "Login": {
      const urlPath = window.location.pathname;
      const urlQuery = window.location.search;
      const redirectUrl = urlPath + urlQuery;
      const redirectState: RedirectState = { redirectUrl };
      const uiLocales = getUiLocalesParam(redirectUrl);
      const extraQueryParams = uiLocales ? { ui_locales: uiLocales } : undefined;
      return [
        {
          type: "WaitingForUserSessionState",
          urlPath,
          urlQuery,
        },
        OidcEffectManager.login(userManagerSettings, redirectState, extraQueryParams),
      ];
    }
    case "Logout": {
      return [{ type: "LoggedOutState" }, OidcEffectManager.logout()];
    }
    case "AccessTokenRefreshed": {
      if (state.type !== "LoggedInState") {
        return [state];
      }
      const { user } = action;
      const activeUser = User.buildActiveUser(user, user.access_token);
      if (!User.isValidUser(activeUser)) {
        return [
          {
            ...state,
            type: "ErrorState",
            reason: activeUser.reason,
          },
        ];
      }
      return [{ ...state, activeUser: activeUser }];
    }
    case "UserSessionChanged": {
      const { user } = action;
      switch (state.type) {
        case "LoggedInState": {
          // If we have no user then set state as logged out
          if (user === undefined) {
            return [{ type: "LoggedOutState" }];
          }
          return [state];
        }
        case "WaitingForUserSessionState": {
          //If we got an undefind user then there was some error in the login flow
          if (user === undefined) {
            return [{ type: "ErrorState", reason: "OIDC user is undefined" }];
          }
          const activeUser = User.buildActiveUser(user, user.access_token);
          if (!User.isValidUser(activeUser)) {
            return [
              {
                ...state,
                type: "ErrorState",
                reason: activeUser.reason,
              },
            ];
          }

          // Set active user for sentry reporting, side-effect in reducer, not nice!!
          setUser(activeUser.email);

          const redirectState = user.state as RedirectState;
          const originalUrl =
            redirectState && redirectState.redirectUrl ? redirectState.redirectUrl : state.urlPath + state.urlQuery;

          const locale = getUiLocalesParam(originalUrl) || Texts.defaultLanguage;

          return [
            {
              type: "LoggedInState",
              activeUser,
              urlMatch: undefined,
              formState: undefined,
              waitingForResponse: false,
              errorResponse: false,
              translationsFile: undefined,
              locale: locale,
            },
            Cmd.batch([
              Navigation.replaceUrl(originalUrl),
              HttpEffectManager.fetchOne({}, "/translations", "json", (data: Texts.TranslationsFile) =>
                Action.ReceivedTranslations(data)
              ),
            ]),
          ];
        }
        case "LoggedOutState": {
          // No actions should be generated but it seems OIDC manager still does after the session has expired
          return [state];
        }
        default:
          return exhaustiveCheck(state, true);
      }
    }

    case "UrlChanged": {
      switch (state.type) {
        case "LoggedInState": {
          const urlMatch = Routes.parseUrl(action.url.path + (action.url.query ?? ""));

          const cmds = [];

          if (urlMatch === undefined) {
            // If the current location is undefined then goto the default location
            const defaultLocation = Routes.RootLocation.FormLocation([], "", false, "", undefined);
            const defaultUrl = Routes.buildUrl(defaultLocation);

            // Safety-check that defaultUrl really has a match becuase otherwise we will be stuck in client-side redirect-loop
            const defaultMatch = Routes.parseUrl(defaultUrl);
            if (defaultMatch === undefined) {
              throw new Error("Default URL does not match a route.");
            }

            cmds.push(Navigation.replaceUrl<Action>(defaultUrl));

            return [{ ...state }, Cmd.batch(cmds)];
          } else {
            const newState = { ...state, urlMatch };
            switch (urlMatch.location.type) {
              case "LoginCallback":
                // LoginCallback can only be triggered in init() as it starts the application
                return [{ type: "ErrorState", reason: "LoginCallback error" }];
              case "LoggedOut":
                return [{ type: "LoggedOutState" }];

              case "FormLocation": {
                const [formState, mainCmd] = Form.init(
                  state.locale,
                  state.activeUser,
                  state.formState,
                  urlMatch.location.missingClaims,
                  urlMatch.location.redirectToUrl,
                  urlMatch.location.logoutOnConfirm,
                  urlMatch.location.sysSsoLocationAvaialable
                );
                cmds.push(Cmd.map(Action.DispatchForm, mainCmd));
                return [{ ...newState, urlMatch, formState }, Cmd.batch(cmds)];
              }
              default:
                return exhaustiveCheck(urlMatch.location, true);
            }
          }
        }

        default:
          // In other states this action has no relevance
          return [state];
      }
    }

    case "UrlRequested":
      switch (action.urlRequest.type) {
        case "InternalUrlRequest":
          return [state, Navigation.pushUrl(action.urlRequest.url)];
        case "ExternalUrlRequest":
          return [state, Navigation.load(Navigation.toString(action.urlRequest.url))];
        default:
          return exhaustiveCheck(action.urlRequest);
      }

    case "HttpStateChanged": {
      if (state.type !== "LoggedInState") {
        return [state];
      }

      let newState = state;
      if (action.httpState === "waiting") {
        newState = {
          ...state,
          waitingForResponse: true,
        };
      }
      if (action.httpState === "idle") {
        newState = {
          ...state,
          waitingForResponse: false,
        };
      }
      if (action.httpState === "error") {
        newState = {
          ...state,
          errorResponse: true,
        };
      }

      return [newState];
    }

    case "DispatchForm": {
      if (state.type !== "LoggedInState" || !state.formState) {
        return [state];
      }
      const sharedState = buildSharedState(state);
      const [formState, formCmd, sharedStateAction] = Form.update(action.action, state.formState, sharedState);
      const newState = { ...state, formState };
      const [stateUpdatedShared, sharedCmd] = handleSharedStateAction(sharedStateAction, newState);
      return [stateUpdatedShared, Cmd.batch<Action>([Cmd.map(Action.DispatchForm, formCmd), sharedCmd])];
    }

    case "SilentRenew": {
      if (state.type !== "LoggedInState") {
        return [state];
      }
      return [state, OidcEffectManager.manualSilentRenew()];
    }

    case "ReceivedTranslations": {
      if (state.type !== "LoggedInState") {
        return [state];
      }
      return [{ ...state, translationsFile: action.translationsFile }];
    }

    default: {
      return exhaustiveCheck(action, true);
    }
  }
}

export function buildSharedState(state: LoggedInState): SharedState {
  return {
    locale: state.locale,
    activeUser: state.activeUser,
    availableLocales: Object.keys(state.translationsFile || {}),
  };
}

function getUiLocalesParam(url: string): string | undefined {
  const urlMatch = Routes.parseUrl(url);
  return urlMatch?.location.type === "FormLocation" ? urlMatch.location.uiLocales?.toLowerCase() : undefined;
}
