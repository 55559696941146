import { CustomerNumberInfo } from "./customer-number-info";
import { Country } from "./locales";

export type FromDataResponse = {
  readonly locales: LocalesResponse;
  readonly storyblok: StoryblokResponse;
  readonly keycloakLocales: ReadonlyArray<string>;
};

export type LocalesResponse = {
  readonly countries: ReadonlyArray<Country>;
};

export type StoryblokResponse = {
  readonly customerNumberInfo: CustomerNumberInfo;
};
