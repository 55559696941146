import React from "react";
import { FormData } from "@ka/shared";
import { texts, TranslateFn } from "@ka/shared/src/lang-texts";

type Language = {
  readonly countryCode: string;
  readonly locale: string;
  readonly label: string;
};

export function LanguageSelector({
  translate,
  onSetSelectorVisiblity,
  onSetLocale,
  countries,
  availableLocales,
  showSelector,
  selectedLocale,
}: {
  readonly translate: TranslateFn;
  readonly onSetSelectorVisiblity: (show: boolean) => void;
  readonly onSetLocale: (locale: string) => void;
  readonly countries: ReadonlyArray<FormData.Country>;
  readonly availableLocales: ReadonlyArray<string>;
  readonly showSelector: boolean;
  readonly selectedLocale: string;
}): JSX.Element | null {
  const languageByLocale = new Map<string, Language>(
    countries.flatMap((c) =>
      c.languages.map((l) => {
        const labelParts = [c.englishCountryName, l.nativeCountryName];
        if (c.languages.length > 1) {
          labelParts.push(l.nativeLanguageName || l.englishLanguageName);
        }
        const label = [...new Set(labelParts)].filter((p) => !!p).join(" · ");
        return [
          l.locale.toLowerCase(),
          {
            countryCode: c.countryCode.toLowerCase(),
            locale: l.locale.toLowerCase(),
            label: label,
          },
        ];
      })
    )
  );

  const languages = availableLocales
    .map((l) => languageByLocale.get(l.toLowerCase()))
    .filter((l): l is Language => !!l)
    .sort((a, b) => a.label.localeCompare(b.label));

  const globalLanguage: Language = {
    countryCode: "global",
    locale: "en",
    label: translate(texts.global_site_language),
  };

  const language = languages.find((l) => l.locale === selectedLocale) || globalLanguage;

  if (languages.length === 0) {
    return null;
  }

  return (
    <div>
      <div>
        <div
          className="flex flex-row justify-end items-center cursor-pointer"
          onClick={() => onSetSelectorVisiblity(true)}
        >
          {language.locale === globalLanguage.locale ? <GlobeIcon /> : <FlagIcon countryCode={language.countryCode} />}
          <div className="text-label-small whitespace-nowrap">{language.label}</div>
          <ChevronDownIcon />
        </div>
      </div>
      <div className={`fixed bg-white inset-0 w-full overflow-auto px-40 py-16 ${showSelector ? "" : "hidden"}`}>
        <div className="flex flex-row justify-between">
          <div className="w-2/12 py-8"></div>
          <a className="text-label-small max-w-16 cursor-pointer" onClick={() => onSetSelectorVisiblity(false)}>
            <CrossIcon />
          </a>
        </div>
        <ul className="language-list p-0 space-y-16">
          {languages.map((l) => (
            <li key={l.locale} className="text-label-small list-none">
              <a
                className="flex flex-row items-center w-fit-content cursor-pointer"
                onClick={() => {
                  onSetSelectorVisiblity(false);
                  onSetLocale(l.locale);
                }}
              >
                <FlagIcon countryCode={l.countryCode} />
                {l.label}
              </a>
            </li>
          ))}
        </ul>
        <div className="flex flex-row justify-center border-t border-neutral-200 mt-48 px-24">
          <a
            className="flex flex-row items-center text-label-small mt-16 cursor-pointer"
            onClick={() => {
              onSetSelectorVisiblity(false);
              onSetLocale(globalLanguage.locale);
            }}
          >
            <GlobeIcon />
            <div>{globalLanguage.label}</div>
          </a>
        </div>
      </div>
    </div>
  );
}

function CrossIcon(): JSX.Element {
  return (
    <svg className="w-full" viewBox="0 0 320 512">
      <path
        fill="currentColor"
        d="M315.3 411.3c-6.253 6.253-16.37 6.253-22.63 0L160 278.6l-132.7 132.7c-6.253 6.253-16.37 6.253-22.63 0c-6.253-6.253-6.253-16.37 0-22.63L137.4 256L4.69 123.3c-6.253-6.253-6.253-16.37 0-22.63c6.253-6.253 16.37-6.253 22.63 0L160 233.4l132.7-132.7c6.253-6.253 16.37-6.253 22.63 0c6.253 6.253 6.253 16.37 0 22.63L182.6 256l132.7 132.7C321.6 394.9 321.6 405.1 315.3 411.3z"
      ></path>
    </svg>
  );
}

function GlobeIcon(): JSX.Element {
  return (
    <svg className="max-w-20 min-w-16 mr-100" viewBox="0 0 512 512">
      <path
        fill="currentColor"
        d="M256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0zM256 480C272.7 480 296.4 465.6 317.9 422.7C327.8 402.9 336.1 378.1 341.1 352H170C175.9 378.1 184.2 402.9 194.1 422.7C215.6 465.6 239.3 480 256 480V480zM164.3 320H347.7C350.5 299.8 352 278.3 352 256C352 233.7 350.5 212.2 347.7 192H164.3C161.5 212.2 160 233.7 160 256C160 278.3 161.5 299.8 164.3 320V320zM341.1 160C336.1 133 327.8 109.1 317.9 89.29C296.4 46.37 272.7 32 256 32C239.3 32 215.6 46.37 194.1 89.29C184.2 109.1 175.9 133 170 160H341.1zM379.1 192C382.6 212.5 384 233.9 384 256C384 278.1 382.6 299.5 379.1 320H470.7C476.8 299.7 480 278.2 480 256C480 233.8 476.8 212.3 470.7 192H379.1zM327.5 43.66C348.5 71.99 365.1 112.4 374.7 160H458.4C432.6 105.5 385.3 63.12 327.5 43.66V43.66zM184.5 43.66C126.7 63.12 79.44 105.5 53.56 160H137.3C146.9 112.4 163.5 71.99 184.5 43.66V43.66zM32 256C32 278.2 35.24 299.7 41.28 320H132C129.4 299.5 128 278.1 128 256C128 233.9 129.4 212.5 132 192H41.28C35.24 212.3 32 233.8 32 256V256zM458.4 352H374.7C365.1 399.6 348.5 440 327.5 468.3C385.3 448.9 432.6 406.5 458.4 352zM137.3 352H53.56C79.44 406.5 126.7 448.9 184.5 468.3C163.5 440 146.9 399.6 137.3 352V352z"
      ></path>
    </svg>
  );
}

function FlagIcon({ countryCode }: { readonly countryCode: string }): JSX.Element {
  return (
    <img
      className="max-w-32 mr-6"
      src={`https://www.systemair.com/_next/image?url=%2Fassets%2Fflags%2F${countryCode.toLowerCase()}.svg&w=32&q=80`}
    />
  );
}

function ChevronDownIcon(): JSX.Element {
  return (
    <svg className="w-16 text-neutral-600 ml-100 text-label-small leading-none" viewBox="0 0 448 512">
      <path
        fill="currentColor"
        d="M4.251 181.1C7.392 177.7 11.69 175.1 16 175.1c3.891 0 7.781 1.406 10.86 4.25l197.1 181.1l197.1-181.1c6.5-6 16.64-5.625 22.61 .9062c6 6.5 5.594 16.59-.8906 22.59l-208 192c-6.156 5.688-15.56 5.688-21.72 0l-208-192C-1.343 197.7-1.749 187.6 4.251 181.1z"
      ></path>
    </svg>
  );
}
