import { TextKeyName } from "../lang-texts";

export type ValidationResults = { readonly [attributeName: string]: ValidationResult };

export type ValidationResult = ValueValid | ValueOk;

export type ValueValid = {
  readonly valid: true;
};
export type ValueOk = {
  readonly valid: false;
  readonly messageKey: TextKeyName;
};
