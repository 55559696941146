import { exhaustiveCheck } from "ts-exhaustive-check";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import { Cmd } from "@typescript-tea/core";
import { Keycloak, User, FormData, AttributeValidator } from "@ka/shared";
import { HttpEffectManager } from "../../effect-managers";
import { SharedStateAction, SharedState } from "../root/state";

export const customerNumberClaim = "sys_sso_customer_number";
export const customerNumberNotesClaim = "customer_number_note";
export const customerNumberClaims = [customerNumberClaim, "sys_sso_company", customerNumberNotesClaim];

export type AttributeValues = { readonly [attributeName: string]: Keycloak.AttributeValue };

export type SubmitStatus = "submitting" | "ready";

export type LegalText = {
  readonly title: string;
  readonly type: string;
  readonly link: string;
};

export type State = {
  readonly customerNumberAttributes: ReadonlyArray<Keycloak.Attribute> | undefined;
  readonly additionalAttributes: ReadonlyArray<Keycloak.Attribute> | undefined;
  readonly userInfoAttributes: ReadonlyArray<Keycloak.Attribute>;
  readonly missingClaims: ReadonlyArray<string>;
  readonly sysSsoLocationAvaialable: string;
  readonly redirectToUrl: string;
  readonly logoutOnConfirm: boolean;
  readonly attributeValues: AttributeValues;
  readonly fromData: FormData.FromDataResponse | undefined;
  readonly validationResults: AttributeValidator.ValidationResults;
  readonly submitStatus: SubmitStatus;
  readonly legalTexts: ReadonlyArray<LegalText> | undefined;
};

interface AttributeConfigResponse {
  readonly attributeConfig: Keycloak.AttributeConfiguration;
  readonly currentClaims: User.UserClaims;
}

export const Action = ctorsUnion({
  ReceivedAttributeConfig: (response: AttributeConfigResponse) => ({ response }),
  UpdateClaim: (value: Keycloak.AttributeValue) => ({ value }),
  ValidateClaims: (onSuccess: () => void) => ({ onSuccess }),
  ReceivedValidationResponse: (response: AttributeValidator.ValidationResults, onSuccess: () => void) => ({
    response,
    onSuccess,
  }),
  SetSubmitStatus: (submitStatus: SubmitStatus) => ({ submitStatus }),
  ReceivedData: (response: FormData.FromDataResponse) => ({ response }),
  SetLocale: (locale: string) => ({ locale }),
  ReceivedLegalTexts: (fetchedFallback: boolean, texts: ReadonlyArray<LegalText>) => ({
    fetchedFallback,
    texts,
  }),
});
export type Action = CtorsUnion<typeof Action>;

export function init(
  locale: string,
  activeUser: User.ActiveUser,
  _prevState: State | undefined,
  missingClaimsParam: ReadonlyArray<string> | undefined,
  redirectToUrl: string | undefined,
  logoutOnConfirm: boolean,
  sysSsoLocationAvaialable: string
): readonly [State, Cmd<Action>?] {
  const missingClaimsSet = new Set(missingClaimsParam || []);
  if (missingClaimsSet.has(customerNumberClaim)) {
    customerNumberClaims.forEach((claim) => missingClaimsSet.add(claim));
  }
  const missingClaims = Array.from(missingClaimsSet);

  const fetchAttributesCmd = HttpEffectManager.fetchOne(
    {
      Authorization: `Bearer ${activeUser.accessToken}`,
    },
    `/attribute-config?missing_claims=${encodeURIComponent(
      [...missingClaims, "sys_sso_locale"].join(",")
    )}&sys_sso_location_available=${sysSsoLocationAvaialable}`,
    "json",
    (data: AttributeConfigResponse) => Action.ReceivedAttributeConfig(data)
  );

  // Create "fake" attribute for first and last name
  const userInfoAttributes: Array<Keycloak.Attribute> = [];
  if (missingClaims.includes("firstname")) {
    const attribute: Keycloak.Attribute = {
      type: "text",
      realm: "META",
      name: Keycloak.firstNameAttributeName,
      readOnly: false,
      optional: false,
    };
    userInfoAttributes.push(attribute);
  }
  if (missingClaims.includes("lastname")) {
    const attribute: Keycloak.Attribute = {
      type: "text",
      realm: "META",
      name: Keycloak.lastNameAttributeName,
      readOnly: false,
      optional: false,
    };
    userInfoAttributes.push(attribute);
  }

  const localeDateCmd = createFetchLocaleDataCommand(activeUser, locale);
  const legalTextsCmd = createFetchLegalTextsCmd(locale);

  return [
    {
      customerNumberAttributes: undefined,
      additionalAttributes: undefined,
      userInfoAttributes: userInfoAttributes,
      missingClaims: missingClaims,
      sysSsoLocationAvaialable: sysSsoLocationAvaialable,
      redirectToUrl: redirectToUrl || "",
      logoutOnConfirm: logoutOnConfirm,
      attributeValues: {},
      fromData: undefined,
      validationResults: {},
      submitStatus: "ready",
      legalTexts: undefined,
    },
    Cmd.batch([fetchAttributesCmd, localeDateCmd, legalTextsCmd]),
  ];
}

export function update(
  action: Action,
  state: State,
  sharedState: SharedState
): readonly [State, Cmd<Action>?, SharedStateAction?] {
  switch (action.type) {
    case "ReceivedAttributeConfig": {
      const response = action.response;
      const attributeConfig = response.attributeConfig.filter((ac) => state.missingClaims.includes(ac.name));
      const customerNumberAttributes = attributeConfig.filter((a) => customerNumberClaims.includes(a.name));
      const additionalAttributes = attributeConfig.filter((a) => !customerNumberClaims.includes(a.name));
      const attributeValues = Object.fromEntries(
        Keycloak.attributeValuesFromClaims(attributeConfig, response.currentClaims).map((v) => [v.attribute.name, v])
      );

      // Add current first/last name so it can be displayed in the UI
      let userInfoValues = {};
      const firstNameAttribute = state.userInfoAttributes.find((a) => a.name === Keycloak.firstNameAttributeName);
      if (firstNameAttribute?.type === "text") {
        userInfoValues = {
          ...userInfoValues,
          [Keycloak.firstNameAttributeName]: Keycloak.createTextValue(
            firstNameAttribute,
            response.currentClaims[Keycloak.firstNameAttributeName] || ""
          ),
        };
      }
      const lastNameAttribute = state.userInfoAttributes.find((a) => a.name === Keycloak.lastNameAttributeName);
      if (lastNameAttribute?.type === "text") {
        userInfoValues = {
          ...userInfoValues,
          [Keycloak.lastNameAttributeName]: Keycloak.createTextValue(
            lastNameAttribute,
            response.currentClaims[Keycloak.lastNameAttributeName] || ""
          ),
        };
      }
      return [
        {
          ...state,
          customerNumberAttributes: customerNumberAttributes,
          additionalAttributes: additionalAttributes,
          attributeValues: { ...userInfoValues, ...attributeValues },
        },
      ];
    }

    case "UpdateClaim": {
      if (state.submitStatus === "submitting") {
        return [state];
      }
      let validationResults = state.validationResults;
      if (action.value.attribute.validator) {
        validationResults = Object.fromEntries(
          Object.entries(validationResults).filter((e) => e[0] !== action.value.attribute.name)
        );
      }
      return [
        {
          ...state,
          attributeValues: { ...state.attributeValues, [action.value.attribute.name]: action.value },
          validationResults,
          submitStatus: "ready",
        },
      ];
    }

    case "ReceivedData": {
      return [{ ...state, fromData: action.response }];
    }

    case "ValidateClaims": {
      const params = [];
      for (const value of Object.values(state.attributeValues)) {
        if (!value.attribute.validator) {
          continue;
        }
        let valuePart = "";
        switch (value.type) {
          case "text":
            valuePart = value.value;
            break;

          case "bool":
            valuePart = value.value.toString();
            break;

          case "discrete":
            valuePart = value.value || "";
            break;

          case "locale":
            valuePart = value.locale || "";
            break;
          default:
            continue;
        }
        const paramPart = `claim_${value.attribute.name}`;
        params.push(valuePart ? `${paramPart}=${valuePart}` : paramPart);
      }
      const fetchCmd =
        params.length > 0
          ? HttpEffectManager.fetchOne(
              {
                Authorization: `Bearer ${sharedState.activeUser.accessToken}`,
              },
              `/validate-claims?${[...params, `sys_sso_location_available=${state.sysSsoLocationAvaialable}`].join(
                "&"
              )}&`,
              "json",
              (data: AttributeValidator.ValidationResults) => Action.ReceivedValidationResponse(data, action.onSuccess)
            )
          : undefined;
      return [{ ...state, submitStatus: "submitting" }, fetchCmd];
    }

    case "ReceivedValidationResponse": {
      const hasErrors = Object.values(action.response).some((r) => !r.valid);
      if (!hasErrors) {
        action.onSuccess();
        return [state];
      } else {
        return [{ ...state, validationResults: action.response, submitStatus: "ready" }];
      }
    }

    case "SetSubmitStatus": {
      return [{ ...state, submitStatus: action.submitStatus }];
    }

    case "SetLocale": {
      return [
        { ...state, fromData: undefined, legalTexts: undefined },
        Cmd.batch([
          createFetchLocaleDataCommand(sharedState.activeUser, action.locale),
          createFetchLegalTextsCmd(action.locale),
        ]),
        SharedStateAction.SetLocale(action.locale),
      ];
    }

    case "ReceivedLegalTexts": {
      const legalTexts = action.texts.filter((t) => t.type !== "");
      if (legalTexts.some((t) => t.type === "privacy" || t.type === "terms")) {
        return [{ ...state, legalTexts }];
      } else if (!action.fetchedFallback) {
        return [state, createFetchLegalTextsCmd(undefined)];
      } else {
        return [{ ...state, legalTexts: [] }];
      }
    }

    default: {
      return exhaustiveCheck(action, true);
    }
  }
}

function createFetchLocaleDataCommand(
  activeUser: User.ActiveUser,
  locale: string | undefined
): Cmd<Action> | undefined {
  const fetchCmd = HttpEffectManager.fetchOne(
    {
      Authorization: `Bearer ${activeUser.accessToken}`,
    },
    `/form-data?locale=${locale}`,
    "json",
    (data: FormData.FromDataResponse) => Action.ReceivedData(data),
    () =>
      Action.ReceivedData({
        locales: {
          countries: [],
        },
        storyblok: {
          customerNumberInfo: {
            mainInfo: {
              message: "",
              title: "",
            },
            paragraphs: [],
          },
        },
        keycloakLocales: [],
      })
  );
  return fetchCmd;
}

function createFetchLegalTextsCmd(locale: string | undefined): Cmd<Action> | undefined {
  return HttpEffectManager.fetchOne(
    {},
    `https://www.systemair.com/api/${locale || "en"}/legal`,
    "json",
    (data: ReadonlyArray<LegalText>) => Action.ReceivedLegalTexts(locale === undefined, data),
    () => Action.ReceivedLegalTexts(locale === undefined, [])
  );
}
