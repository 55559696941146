export type MainInfo = {
  readonly title: string;
  readonly message: string;
};

export type CustomerNumberInfo = {
  readonly mainInfo: MainInfo;
  readonly paragraphs: ReadonlyArray<Paragraph>;
};

export type Email = {
  readonly email: string;
  readonly image?: string;
};

export type Phone = {
  readonly phone: string;
  readonly image?: string;
};

export type Paragraph = {
  readonly heading: string;
  readonly image?: string;
  readonly text: string;
  readonly email?: Email;
  readonly phone?: Phone;
};
