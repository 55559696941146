import * as React from "react";

export function SpinnerOverlay({ disableDelay = false }: { readonly disableDelay?: boolean }): JSX.Element | null {
  const [showSpinner, setShowSpinner] = React.useState(disableDelay ? true : false);
  React.useEffect(() => {
    if (!disableDelay) {
      const timer = setTimeout(() => {
        setShowSpinner(true);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      return () => undefined;
    }
  }, [disableDelay]);
  return showSpinner ? (
    <div className="fixed left-0 top-0 z-60 h-full w-full flex items-center justify-center bg-neutral-50">
      <SpinnerIcon />
    </div>
  ) : null;
}

function SpinnerIcon({ className }: { readonly className?: string }): JSX.Element {
  return (
    <svg className={`animate-spin max-w-36 max-h-36 ${className || ""}`} fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
}
