import { KeyParams } from "./normalized-text";

export type TextTypes = TextKey | Dummy;

export type TextKey = {
  readonly type: "TextKey";
  readonly key: string;
  readonly params: KeyParams;
};

export type Dummy = {
  readonly type: "Dummy";
  readonly key: string;
  readonly params: KeyParams;
};

export const key = (key: string, params: KeyParams = {}): TextKey => ({
  type: "TextKey",
  key,
  params,
});

export type TextKeyName = keyof typeof plainTexts;

const plainTexts = {
  no_redirect_specified: key("no_redirect_specified"),
  missing_claims: key("missing_claims"),
  complete_account: key("complete_account"),
  enter_missing_info: key("enter_missing_info"),
  confirm_and_continue: key("confirm_and_continue"),
  country: key("country"),
  language: key("language"),
  text_length: key("text_length"),
  not_a_valid_vat_number: key("not_a_valid_vat_number"),
  vat_api_error: key("vat_api_error"),
  not_a_valid_country: key("not_a_valid_country"),
  global_site_language: key("global_site_language"),
};

const fnTexts = {
  attribute_name: (attributeKey: string) => key(`attribute_${attributeKey}`),
  attribute_value: (attributeKey: string, value: string) => key(`attribute_${attributeKey}_${value}`),
};

export const texts = {
  ...plainTexts,
  ...fnTexts,
};

export type SourceTexts = { readonly [textKey: string]: string };
