import { exhaustiveCheck } from "ts-exhaustive-check";
import { Dispatch, EffectManager } from "@typescript-tea/core";
import { TimerCmd, mapCmd } from "./timer-cmd";
import { home } from "./home";

export function createEffectManager<ActionApp>(): EffectManager {
  return {
    home,
    mapCmd,
    mapSub: (_, _sub) => {
      throw new Error("This effect manager has no subs.");
    },
    setup: () => () => undefined,
    onEffects: (
      dispatchApp: Dispatch<ActionApp>,
      dispatchSelf: Dispatch<never>,
      cmds: readonly TimerCmd<ActionApp>[],
      subs: readonly never[],
      state: State = init()
    ) => onEffects(dispatchApp, dispatchSelf, cmds, subs, state || init()),
    onSelfAction: () => ({}),
  };
}

// -- STATE

interface State {
  readonly currentTimers: ReadonlyMap<string, ReturnType<typeof setTimeout>>;
}

function init(): State {
  return { currentTimers: new Map() };
}

// -- COMMANDS

// -- SUBSCRIPTIONS

// -- MANAGER

function onEffects<ActionApp>(
  dispatchApp: Dispatch<ActionApp>,
  _dispatchSelf: Dispatch<never>,
  cmds: readonly TimerCmd<ActionApp>[],
  _subs: readonly never[],
  state: State
): State {
  for (const cmd of cmds) {
    switch (cmd.type) {
      case "SetTimeout": {
        const currentTimers = new Map(state.currentTimers);
        const currentTimer = currentTimers.get(cmd.timerId);
        if (currentTimer !== undefined) {
          clearTimeout(currentTimer);
        }
        const newTimer = setTimeout(() => dispatchApp(cmd.onTimeout()), cmd.timeoutMilliseconds);
        currentTimers.set(cmd.timerId, newTimer);
        return { ...state, currentTimers };
      }
      default: {
        exhaustiveCheck(cmd.type, true);
      }
    }
  }
  return state;
}
