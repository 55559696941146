import { exhaustiveCheck } from "ts-exhaustive-check";
import { home } from "./home";

export type HttpFetchCmd<A, R> = FetchOne<A, R> | FetchMultiple<A, R> | Post<A, R> | Put | Delete;

export type HttpResponseType = "none" | "text" | "json" | "blob";
export type HttpResponse<T> = T extends "text"
  ? string
  : T extends "json"
  ? unknown
  : T extends "none"
  ? undefined
  : Uint8Array;

export interface FetchOne<A, R> {
  readonly home: typeof home;
  readonly type: "FetchOne";
  readonly headers: { readonly [header: string]: string };
  readonly url: string;
  readonly responseType: HttpResponseType;
  readonly onSuccess?: (response: R) => A;
  readonly onError?: () => A;
}

export interface FetchMultiple<A, R> {
  readonly home: typeof home;
  readonly type: "FetchMultiple";
  readonly headers: { readonly [header: string]: string };
  readonly urls: readonly string[];
  readonly responseType: HttpResponseType;
  readonly onSuccess?: (responses: readonly R[]) => A;
}

export interface Post<A, R> {
  readonly home: typeof home;
  readonly type: "Post";
  readonly headers: { readonly [header: string]: string };
  readonly url: string;
  readonly contentType: "application/json";
  readonly body: string;
  readonly responseType: HttpResponseType;
  readonly onSuccess?: (response: R) => A;
}

export interface Put {
  readonly home: typeof home;
  readonly type: "Put";
  readonly headers: { readonly [header: string]: string };
  readonly url: string;
  readonly contentType: "application/json";
  readonly body: string;
}

export interface Delete {
  readonly home: typeof home;
  readonly type: "Delete";
  readonly headers: { readonly [header: string]: string };
  readonly url: string;
  readonly contentType: "application/json";
  readonly body: string;
}

export function fetchOne<A, T extends HttpResponseType, R = HttpResponse<T>>(
  headers: { readonly [header: string]: string },
  url: string,
  responseType: T,
  onSuccess?: (response: R) => A,
  onError?: () => A
): FetchOne<A, R> {
  return {
    home: "http-fetch",
    type: "FetchOne",
    headers,
    url,
    responseType,
    onSuccess,
    onError,
  };
}

export function fetchMultiple<A, T extends HttpResponseType, R = HttpResponse<T>>(
  headers: { readonly [header: string]: string },
  urls: readonly string[],
  responseType: T,
  onSuccess?: (responses: readonly R[]) => A
): FetchMultiple<A, R> {
  return {
    home: "http-fetch",
    type: "FetchMultiple",
    headers,
    urls,
    responseType,
    onSuccess,
  };
}

export function post<A, T extends HttpResponseType, R = HttpResponse<T>>(
  headers: { readonly [header: string]: string },
  url: string,
  responseType: T,
  contentType: "application/json",
  body: string,
  onSuccess?: (response: R) => A
): Post<A, R> {
  return {
    home: "http-fetch",
    type: "Post",
    headers,
    url,
    contentType,
    body,
    responseType,
    onSuccess,
  };
}

export function put(
  headers: { readonly [header: string]: string },
  url: string,
  contentType: "application/json",
  body: string
): Put {
  return {
    home: "http-fetch",
    type: "Put",
    headers,
    url,
    contentType,
    body,
  };
}

export function deleteOne(
  headers: { readonly [header: string]: string },
  url: string,
  contentType: "application/json",
  body: string
): Delete {
  return {
    home: "http-fetch",
    type: "Delete",
    headers,
    url,
    contentType,
    body,
  };
}

export function mapCmd<A1, A2>(actionMapper: (a: A1) => A2, cmd: HttpFetchCmd<A1, unknown>): HttpFetchCmd<A2, unknown> {
  switch (cmd.type) {
    case "Post": {
      const onSuccess = cmd.onSuccess;
      return {
        ...cmd,
        onSuccess: onSuccess && ((response: unknown) => actionMapper(onSuccess(response))),
      };
    }
    case "FetchOne": {
      const onSuccess = cmd.onSuccess;
      const onError = cmd.onError;
      return {
        ...cmd,
        onSuccess: onSuccess && ((response: unknown) => actionMapper(onSuccess(response))),
        onError: onError && (() => actionMapper(onError())),
      };
    }
    case "FetchMultiple": {
      const onSuccess = cmd.onSuccess;
      return {
        ...cmd,
        onSuccess: onSuccess && ((responses: readonly unknown[]) => actionMapper(onSuccess(responses))),
      };
    }
    case "Put":
    case "Delete":
      return cmd;
    default: {
      return exhaustiveCheck(cmd, true);
    }
  }
}
