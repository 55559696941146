import loglevel from "loglevel";

setLogLevel("info");

export function setLogLevel(level: "warn" | "error" | "debug" | "info"): void {
  loglevel.setDefaultLevel(level);
  loglevel.setLevel(level);
}

export const log = loglevel.info;
export const logError = loglevel.error;
export const logWarn = loglevel.warn;
export const logInfo = loglevel.info;
export const logDebug = loglevel.debug;
