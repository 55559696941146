export type Country = {
  readonly countryCode: string;
  readonly englishCountryName: string;
  readonly languages: ReadonlyArray<Language>;
};

export type Language = {
  readonly languageCode: string;
  readonly locale: string;
  readonly englishLanguageName: string;
  readonly nativeLanguageName: string;
  readonly nativeCountryName: string;
};

export function getLocalesFromCountries(countries: ReadonlyArray<Country>): ReadonlyArray<string> {
  const locales = [];
  for (const c of countries) {
    for (const l of c.languages) {
      locales.push(`${l.languageCode}-${c.countryCode}`);
    }
  }
  return locales;
}
