import * as Sentry from "@sentry/react";

export function initSentry(dsn: string | undefined, release: string, environment: string): void {
  if (dsn) {
    Sentry.init({
      dsn: dsn,
      environment: environment,
      tracesSampleRate: 1.0,
      release: release,
    });
    //Sentry.setUser({ email: "john.doe@example.com" });
  }
}

export function setUser(email: string): void {
  Sentry.setUser({ email: email });
}
