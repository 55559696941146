import * as ReactDOM from "react-dom";
import { EffectManager, Program } from "@typescript-tea/core";
import { Version } from "@ka/shared";
import { HttpEffectManager, NavigationEffectManager, OidcEffectManager, TimerEffectManager } from "./effect-managers";
import { initSentry } from "./sentry";
import { clientConfig } from "./client-config";
import * as Root from "./pages/root";
import * as Theme from "./theme";
import { registerIcons } from "./elements/icons";

Theme.applyThemeColors("systemair");

registerIcons();

initSentry(clientConfig.dsn, Version.appInfo.pipelineId, clientConfig.env);

const effectManagers: readonly EffectManager[] = [
  NavigationEffectManager.createEffectManager<Root.Action>(Root.Action.UrlChanged, Root.Action.UrlRequested),
  OidcEffectManager.createOidcEffectManager(),
  HttpEffectManager.createEffectManager<Root.Action>(Root.Action.HttpStateChanged),
  TimerEffectManager.createEffectManager<Root.Action>(),
  // LocalStorageEffectManager.createEffectManager(),
];

const rootProgram: Program<NavigationEffectManager.Url, Root.State, Root.Action, JSX.Element> = {
  init: Root.init,
  update: Root.update,
  view: Root.view,
  subscriptions: Root.subscriptions,
};

const rootElement = document.getElementById("root");
const render = (view: JSX.Element): void => ReactDOM.render(view, rootElement);
Program.run(rootProgram, NavigationEffectManager.getCurrentUrl(), render, effectManagers);
