import { exhaustiveCheck } from "ts-exhaustive-check";
import { home } from "./home";

export type TimerCmd<A> = SetTimeout<A>;

export interface SetTimeout<A> {
  readonly home: typeof home;
  readonly type: "SetTimeout";
  readonly timerId: string;
  readonly timeoutMilliseconds: number;
  readonly onTimeout: () => A;
}

export function setTimeout<A>(timerId: string, timeoutMilliseconds: number, onTimeout: () => A): SetTimeout<A> {
  return {
    home: "timer",
    type: "SetTimeout",
    timerId,
    timeoutMilliseconds,
    onTimeout,
  };
}

export function mapCmd<A1, A2>(actionMapper: (a: A1) => A2, cmd: TimerCmd<A1>): TimerCmd<A2> {
  switch (cmd.type) {
    case "SetTimeout": {
      const onTimeout = cmd.onTimeout;
      return {
        ...cmd,
        onTimeout: () => actionMapper(onTimeout()),
      };
    }
    default: {
      return exhaustiveCheck(cmd.type, true);
    }
  }
}
