import React from "react";

//export function withTw<K extends keyof JSX.IntrinsicElements>(
//  element: K,
//  className: string
//): (props: JSX.IntrinsicElements[K]) => JSX.Element {
//  return (props) => React.createElement(element, { ...props, className: `${className} ${props.className || ""}` });
//}

export function withTw<
  ElementTag extends keyof JSX.IntrinsicElements,
  ElementProps = JSX.IntrinsicElements[ElementTag],
  Props = {},
  FullProps = Props & ElementProps
>(
  elementTag: ElementTag,
  ...classNames: readonly (string | ((p: Props) => string))[]
): (props: FullProps) => JSX.Element {
  if (classNames.every((c) => typeof c === "string")) {
    const className = classNames.join(" ");
    return (props) =>
      React.createElement(elementTag, {
        ...props,
        className: [className, (props as React.HTMLAttributes<{}>).className || ""].join(" "),
      });
  } else {
    return (props) => {
      const className = classNames
        .reduce((sofar, c) => {
          if (typeof c === "string") {
            sofar.push(c);
          } else {
            sofar.push(c(props as unknown as Props));
          }
          return sofar;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }, new Array<any>())
        .join(" ");
      // TODO: only forward valid react props
      return React.createElement(elementTag, {
        ...props,
        className: [className, (props as React.HTMLAttributes<{}>).className || ""].join(" "),
      });
    };
  }
}

export function twClass<Props>(
  strings: ReadonlyArray<string>,
  ...args: ReadonlyArray<((p: Props) => string | undefined) | string | undefined>
): (props: Props) => string {
  return (props: Props): string => {
    const classNames = [strings[0]];
    for (let i = 0; i < args.length; i++) {
      const arg = args[i];
      if (arg !== undefined) {
        if (typeof arg === "string") {
          classNames.push(arg);
        } else {
          classNames.push(arg(props) || "");
        }
      }
      classNames.push(strings[i + 1]);
    }
    return classNames.join(" ");
  };
}

export const Input = withTw(
  "input",
  "w-full border py-4 px-4 h-24 hover:shadow-btn-hover focus:outline-none focus:shadow-btn-focus focus:border-primary-light spin-button-none"
);
export const TextArea = withTw(
  "textarea",
  "w-full border py-4 px-4 h-24 hover:shadow-btn-hover focus:outline-none focus:shadow-btn-focus focus:border-primary-light spin-button-none"
);

export const Select = withTw(
  "select",
  "border py-4 h-24 px-4 hover:shadow-btn-hover focus:outline-none focus:shadow-btn-focus focus:border-primary-light",
  ({ disabled }: { readonly disabled: boolean }) => (disabled ? "form-input-disabled" : "bg-white")
);
export const H1 = withTw("h1", "text-xl");
export const H2 = withTw("h2", "text-lg");
export const H3 = withTw("h3", "text-style-heading-h3");
export const H4 = withTw("h3", "text-style-heading-h4");
