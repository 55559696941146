import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

export interface IconProps {
  readonly onClick?: () => void;
  readonly icon: IconDefinition;
  readonly disabled?: boolean;
  readonly className?: string;
  readonly colorClass?: string;
  readonly message?: string;
}

export function Icon(props: IconProps): React.ReactElement<IconProps> {
  const className = `inline-block 
    ${props.disabled || !props.onClick ? "" : "cursor-pointer"} 
    ${props.disabled ? "text-gray-400" : props.colorClass ?? ""} 
    ${props.className ?? ""}`;
  return props.disabled || !props.onClick ? (
    <span className={className} title={props.message}>
      <FontAwesomeIcon icon={props.icon} />
    </span>
  ) : (
    <a
      className={className}
      onClick={() => {
        if (!props.disabled && props.onClick) {
          props.onClick();
        }
      }}
      title={props.message}
    >
      <FontAwesomeIcon icon={props.icon} />
    </a>
  );
}
