import * as React from "react";

export function PageContainer({ children }: { readonly children?: React.ReactNode }): JSX.Element {
  return (
    <div className="bg-white max-w-md py-24 px-40 relative">
      <div className="w-1/2 mb-24">
        <img src="/systemair-logo.svg" />
      </div>
      {children}
    </div>
  );
}

export function SimplePage({
  heading,
  subtitle,
  message,
  children,
}: {
  readonly heading: string;
  readonly subtitle?: string;
  readonly message?: string;
  readonly children?: React.ReactNode;
}): JSX.Element {
  return (
    <div className="text-label-medium flex flex-col items-center justify-center">
      <PageContainer>
        <PageHeader heading={heading} subtitle={subtitle} />
        <div className="flex flex-col space-y-24">
          {message && <div className="text-label-medium">{message}</div>}
          {children}
        </div>
      </PageContainer>
    </div>
  );
}

export function PageHeader({
  heading,
  subtitle,
}: {
  readonly heading: string;
  readonly subtitle?: string;
}): JSX.Element {
  return (
    <header className="mb-24">
      <div className="h2 m-0">{heading}</div>
      {subtitle && <div className="text-label-medium">{subtitle}</div>}
    </header>
  );
}
