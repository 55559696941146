import { getCssTokens } from "@systemair/styles/src/lib/theme";

// eslint-disable-next-line functional/no-let
let styleElement: HTMLStyleElement | undefined = undefined;
export function applyThemeColors(theme: "systemair" | "frico" | "menerga" | "fantech"): void {
  const element = styleElement || document.head.appendChild(document.createElement("style"));
  styleElement = element;
  element.textContent = `:root {
    ${getCssTokens(theme)}
  }`;
}
